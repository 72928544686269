var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "sale-report-create-manually"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit,
          invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.submitHandle);
            }
          }
        }, [_c('b-card', {
          staticClass: "border",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.subTitle')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0 pt-1"
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.agency'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "agency",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.agency')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.agenciesChildList,
                  "reduce": function reduce(val) {
                    return val;
                  },
                  "clearable": true,
                  "label": "agencyCode",
                  "filterable": true,
                  "input-id": "agency",
                  "name": _vm.$t('saleReport.createManually.agency'),
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.agency')
                },
                on: {
                  "open": _vm.openAgencySelect,
                  "input": _vm.inputAgencySelect
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('b-row', [_c('b-col', {
                      staticClass: "font-weight-bold text-truncate",
                      attrs: {
                        "cols": "12"
                      }
                    }, [_vm._v(" " + _vm._s(data.agencyCode) + " ")]), _c('b-col', {
                      attrs: {
                        "cols": "12"
                      }
                    }, [_c('small', {
                      staticClass: "text-truncate"
                    }, [_vm._v(" " + _vm._s(data.agencyName) + " ")])])], 1)];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "d-flex gap-2"
                    }, [_c('div', {
                      staticClass: "text-info"
                    }, [_vm._v(" " + _vm._s(data.agencyCode) + " ")]), _c('div', [_c('small', {
                      staticClass: "text-truncate"
                    }, [_vm._v(" " + _vm._s(data.agencyName) + " ")])])])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn(_ref2) {
                    var searching = _ref2.searching;
                    return [searching ? [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")] : [_c('b-spinner', {
                      attrs: {
                        "variant": "primary",
                        "label": "Text Centered",
                        "small": "",
                        "tag": "span"
                      }
                    }), _c('span', {
                      staticClass: "pl-1"
                    }, [_vm._v(" " + _vm._s(_vm.$t('loading')) + " ")])]];
                  }
                }], null, true),
                model: {
                  value: _vm.agencySelected,
                  callback: function callback($$v) {
                    _vm.agencySelected = $$v;
                  },
                  expression: "agencySelected"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "agency",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.customer'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "customer",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.customer')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.customerOptions,
                  "reduce": function reduce(val) {
                    return val;
                  },
                  "clearable": "",
                  "disabled": !_vm.agencySelected,
                  "label": "code",
                  "filterable": false,
                  "input-id": "customer",
                  "name": _vm.$t('saleReport.createManually.customer'),
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.customer')
                },
                on: {
                  "open": _vm.handleOpenCustomer,
                  "search": _vm.handleSearchCustomer
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('b-row', [_c('b-col', {
                      staticClass: "font-weight-bold text-truncate",
                      attrs: {
                        "cols": "12"
                      }
                    }, [_vm._v(" " + _vm._s(data.code) + " ")]), _c('b-col', {
                      attrs: {
                        "cols": "12"
                      }
                    }, [_c('small', {
                      staticClass: "text-truncate"
                    }, [_vm._v(" " + _vm._s(data.lastName) + " " + _vm._s(data.firstName) + " ")])])], 1)];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "d-flex gap-2"
                    }, [_c('div', {
                      staticClass: "text-info"
                    }, [_vm._v(" " + _vm._s(data.code) + " ")]), _c('div', [_c('small', {
                      staticClass: "text-truncate"
                    }, [_vm._v(" " + _vm._s(data.lastName) + " " + _vm._s(data.firstName) + " ")])])])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm.isLoadingCustomerOptions ? [_c('b-spinner', {
                      attrs: {
                        "variant": "primary",
                        "label": "Text Centered",
                        "small": "",
                        "tag": "span"
                      }
                    }), _c('span', {
                      staticClass: "pl-1"
                    }, [_vm._v(" " + _vm._s(_vm.$t('loading')) + " ")])] : [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")]];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.customerSelected,
                  callback: function callback($$v) {
                    _vm.customerSelected = $$v;
                  },
                  expression: "customerSelected"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "customer",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.ticketNumber'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "ticketNumber"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.ticketNumber')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "ticketNumber",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.ticketNumber')
                },
                model: {
                  value: _vm.saleReportToAdd.ticketNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "ticketNumber", $$v);
                  },
                  expression: "saleReportToAdd.ticketNumber"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "ticketNumber",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.airline'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "airline"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.airline')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "airline",
                  "maxlength": "2",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.upperCaseFormatter,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.airline')
                },
                model: {
                  value: _vm.saleReportToAdd.airline,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "airline", $$v);
                  },
                  expression: "saleReportToAdd.airline"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "airline",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.source'),
            "rules": "required|max:2"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                class: _vm.getValidationState(validationContext) === false ? 'is-invalid' : '',
                attrs: {
                  "label-for": "source"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.source')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "id": "source",
                  "options": _vm.distributorExtendOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "label": "label",
                  "filterable": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.source')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "text-truncate"
                    }, [_vm._v(" " + _vm._s(data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "text-truncate"
                    }, [_vm._v(" " + _vm._s(data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn(_ref3) {
                    var searching = _ref3.searching;
                    return [searching ? [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")] : [_c('b-spinner', {
                      attrs: {
                        "variant": "primary",
                        "label": "Text Centered",
                        "small": "",
                        "tag": "span"
                      }
                    }), _c('span', {
                      staticClass: "pl-1"
                    }, [_vm._v(" " + _vm._s(_vm.$t('loading')) + " ")])]];
                  }
                }], null, true),
                model: {
                  value: _vm.saleReportToAdd.source,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "source", $$v);
                  },
                  expression: "saleReportToAdd.source"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "source",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.bookingCode'),
            "rules": "required|max:6"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "bookingCode"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.bookingCode')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "bookingCode",
                  "maxlength": "6",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.upperCaseFormatter,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.bookingCode')
                },
                model: {
                  value: _vm.saleReportToAdd.bookingCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "bookingCode", $$v);
                  },
                  expression: "saleReportToAdd.bookingCode"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "bookingCode",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.paxName'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "paxName"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.paxName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "paxName",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.removeAccentsUpperCaseFormatter,
                  "lazy-formatter": "",
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.paxName')
                },
                model: {
                  value: _vm.saleReportToAdd.paxName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "paxName", $$v);
                  },
                  expression: "saleReportToAdd.paxName"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "paxName",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.paxType'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "paxType"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.paxType')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "id": "paxType",
                  "options": ['ADULT', 'CHILD', 'INFANT'],
                  "reduce": function reduce(val) {
                    return val;
                  },
                  "clearable": true,
                  "label": "label",
                  "filterable": true,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "name": _vm.$t('saleReport.createManually.paxType'),
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.paxType')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.label))))])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.label))))])];
                  }
                }], null, true),
                model: {
                  value: _vm.saleReportToAdd.paxType,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "paxType", $$v);
                  },
                  expression: "saleReportToAdd.paxType"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "paxType",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.paxId'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "paxId"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.paxId')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "paxId",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.paxId')
                },
                model: {
                  value: _vm.saleReportToAdd.paxId,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "paxId", $$v);
                  },
                  expression: "saleReportToAdd.paxId"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "paxId",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.bookingDate'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "bookingDate"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.bookingDate')) + " (YYYY-MM-DD) ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "bookingDate",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.bookingDate')
                },
                model: {
                  value: _vm.saleReportToAdd.bookingDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "bookingDate", $$v);
                  },
                  expression: "saleReportToAdd.bookingDate"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "bookingDate",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.issueDate'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "issueDate"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.issueDate')) + " (YYYY-MM-DD) ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "issueDate",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.issueDate')
                },
                model: {
                  value: _vm.saleReportToAdd.issueDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "issueDate", $$v);
                  },
                  expression: "saleReportToAdd.issueDate"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "issueDate",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.trips'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "trips"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.trips')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "trips",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.trips')
                },
                model: {
                  value: _vm.saleReportToAdd.trips,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "trips", $$v);
                  },
                  expression: "saleReportToAdd.trips"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "trips",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.bookingClass'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "bookingClass"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.bookingClass')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "bookingClass",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.bookingClass')
                },
                model: {
                  value: _vm.saleReportToAdd.bookingClass,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "bookingClass", $$v);
                  },
                  expression: "saleReportToAdd.bookingClass"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "bookingClass",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.fareBasisCode'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "fareBasisCode"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.fareBasisCode')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "fareBasisCode",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.fareBasisCode')
                },
                model: {
                  value: _vm.saleReportToAdd.fareBasisCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "fareBasisCode", $$v);
                  },
                  expression: "saleReportToAdd.fareBasisCode"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "fareBasisCode",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.booker'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "booker"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.booker')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "booker",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.booker')
                },
                model: {
                  value: _vm.saleReportToAdd.booker,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "booker", $$v);
                  },
                  expression: "saleReportToAdd.booker"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "booker",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.issuer'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "issuer"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.issuer')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "issuer",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.issuer')
                },
                model: {
                  value: _vm.saleReportToAdd.issuer,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "issuer", $$v);
                  },
                  expression: "saleReportToAdd.issuer"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "issuer",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.signBook'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "signBook"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.signBook')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "signBook",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.signBook')
                },
                model: {
                  value: _vm.saleReportToAdd.signBook,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "signBook", $$v);
                  },
                  expression: "saleReportToAdd.signBook"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "signBook",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.signIss'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "signIss"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.signIss')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "signIss",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.signIss')
                },
                model: {
                  value: _vm.saleReportToAdd.signIss,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "signIss", $$v);
                  },
                  expression: "saleReportToAdd.signIss"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "signIss",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.ticketType'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "ticketType"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.ticketType')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "ticketType",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.ticketType')
                },
                model: {
                  value: _vm.saleReportToAdd.ticketType,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "ticketType", $$v);
                  },
                  expression: "saleReportToAdd.ticketType"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "ticketType",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.flightType'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "flightType"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.flightType')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "id": "flightType",
                  "options": [{
                    label: 'saleReport.domestic',
                    value: 'Domestic'
                  }, {
                    label: 'saleReport.inbound',
                    value: 'Inbound'
                  }],
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": true,
                  "label": "label",
                  "filterable": true,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "name": _vm.$t('saleReport.createManually.flightType'),
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.flightType')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(_vm._s(_vm.$t("".concat(data.label))))])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(_vm._s(_vm.$t("".concat(data.label))))])];
                  }
                }], null, true),
                model: {
                  value: _vm.saleReportToAdd.flightType,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "flightType", $$v);
                  },
                  expression: "saleReportToAdd.flightType"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "flightType",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.flownFare'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "flownFare"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.flownFare')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "flownFare",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.flownFare')
                },
                model: {
                  value: _vm.saleReportToAdd.flownFare,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "flownFare", $$v);
                  },
                  expression: "saleReportToAdd.flownFare"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "flownFare",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.flownDate'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "flownDate"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.flownDate')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "flownDate",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.flownDate')
                },
                model: {
                  value: _vm.saleReportToAdd.flownDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "flownDate", $$v);
                  },
                  expression: "saleReportToAdd.flownDate"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "flownDate",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.flightDates'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "flightDates"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.flightDates')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "flightDates",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.flightDates')
                },
                model: {
                  value: _vm.saleReportToAdd.flightDates,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "flightDates", $$v);
                  },
                  expression: "saleReportToAdd.flightDates"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "flightDates",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.currency'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "currency"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.currency')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "currency",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.currency')
                },
                model: {
                  value: _vm.saleReportToAdd.currency,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "currency", $$v);
                  },
                  expression: "saleReportToAdd.currency"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "currency",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.fFlyer'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "fFlyer"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.fFlyer')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "fFlyer",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.fFlyer')
                },
                model: {
                  value: _vm.saleReportToAdd.fFlyer,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "fFlyer", $$v);
                  },
                  expression: "saleReportToAdd.fFlyer"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "fFlyer",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.priceTicket'),
            "rules": "required|min_value:0"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "priceTicket"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.priceTicket')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                staticClass: "d-none",
                attrs: {
                  "value": _vm.saleReportToAdd.priceTicket,
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }), _c('IAmInputMoney', {
                attrs: {
                  "id": "priceTicket",
                  "value-money": _vm.saleReportToAdd.priceTicket,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.priceTicket')
                },
                on: {
                  "update:valueMoney": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "priceTicket", $event);
                  },
                  "update:value-money": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "priceTicket", $event);
                  }
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "priceTicket",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.tax'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "tax"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.tax')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('IAmInputMoney', {
                attrs: {
                  "id": "tax",
                  "value-money": _vm.saleReportToAdd.tax,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.tax')
                },
                on: {
                  "update:valueMoney": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "tax", $event);
                  },
                  "update:value-money": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "tax", $event);
                  }
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "tax",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.otherTax'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "otherTax"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.otherTax')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('IAmInputMoney', {
                attrs: {
                  "id": "otherTax",
                  "value-money": _vm.saleReportToAdd.otherTax,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.otherTax')
                },
                on: {
                  "update:valueMoney": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "otherTax", $event);
                  },
                  "update:value-money": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "otherTax", $event);
                  }
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "otherTax",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.fee'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "fee"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.fee')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('IAmInputMoney', {
                attrs: {
                  "id": "fee",
                  "value-money": _vm.saleReportToAdd.fee,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.fee')
                },
                on: {
                  "update:valueMoney": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "fee", $event);
                  },
                  "update:value-money": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "fee", $event);
                  }
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "fee",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.collectionFee'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "collectionFee"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.collectionFee')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('IAmInputMoney', {
                attrs: {
                  "id": "collectionFee",
                  "value-money": _vm.saleReportToAdd.collectionFee,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.collectionFee')
                },
                on: {
                  "update:valueMoney": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "collectionFee", $event);
                  },
                  "update:value-money": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "collectionFee", $event);
                  }
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "collectionFee",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.feeService'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "feeService"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.feeService')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('IAmInputMoney', {
                attrs: {
                  "id": "feeService",
                  "value-money": _vm.saleReportToAdd.feeService,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.feeService')
                },
                on: {
                  "update:valueMoney": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "feeService", $event);
                  },
                  "update:value-money": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "feeService", $event);
                  }
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "feeService",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.commissionPrepaid'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "commissionPrepaid"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.commissionPrepaid')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('IAmInputMoney', {
                attrs: {
                  "id": "commissionPrepaid",
                  "value-money": _vm.saleReportToAdd.commissionPrepaid,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.commissionPrepaid')
                },
                on: {
                  "update:valueMoney": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "commissionPrepaid", $event);
                  },
                  "update:value-money": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "commissionPrepaid", $event);
                  }
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "commissionPrepaid",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.unpaidCommission'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "unpaidCommission"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.unpaidCommission')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('IAmInputMoney', {
                attrs: {
                  "id": "unpaidCommission",
                  "value-money": _vm.saleReportToAdd.unpaidCommission,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.unpaidCommission')
                },
                on: {
                  "update:valueMoney": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "unpaidCommission", $event);
                  },
                  "update:value-money": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "unpaidCommission", $event);
                  }
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "unpaidCommission",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.commission'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "commission"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.commission')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('IAmInputMoney', {
                attrs: {
                  "id": "commission",
                  "value-money": _vm.saleReportToAdd.commission,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.commission')
                },
                on: {
                  "update:valueMoney": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "commission", $event);
                  },
                  "update:value-money": function updateValueMoney($event) {
                    return _vm.$set(_vm.saleReportToAdd, "commission", $event);
                  }
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "commission",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.originalTk'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "originalTk"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.originalTk')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "originalTk",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.originalTk')
                },
                model: {
                  value: _vm.saleReportToAdd.originalTk,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "originalTk", _vm._n($$v));
                  },
                  expression: "saleReportToAdd.originalTk"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "originalTk",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('saleReport.createManually.ticketSubType'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "ticketSubType"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.ticketSubType')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "ticketSubType",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('saleReport.createManually.placeholder.ticketSubType')
                },
                model: {
                  value: _vm.saleReportToAdd.ticketSubType,
                  callback: function callback($$v) {
                    _vm.$set(_vm.saleReportToAdd, "ticketSubType", _vm._n($$v));
                  },
                  expression: "saleReportToAdd.ticketSubType"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "ticketSubType",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex mt-1 justify-content-center"
        }, [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": _vm.backHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), _c('b-button', {
          staticClass: "mx-1",
          attrs: {
            "variant": "danger",
            "type": "reset",
            "disabled": pristine
          },
          on: {
            "click": _vm.clearHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('clear')) + " ")]), _c('b-button', {
          attrs: {
            "variant": "info",
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('saleReport.createManually.subTitle')) + " ")])], 1)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }